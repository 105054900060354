export const techs = [
	{
		id: '1',
		name: 'HTML',
		logo: 'https://res.cloudinary.com/vinodkotagiri/image/upload/v1665327275/My%20Websites%20assets/logos/html5_jtlfg8.svg',
		color: '#e54a1d',
	},
	{
		id: '2',
		name: 'CSS',
		logo: 'https://res.cloudinary.com/vinodkotagiri/image/upload/v1665327360/My%20Websites%20assets/logos/css3_spcm1e.svg',
		color: '#34aadc',
	},
	{
		id: '3',
		name: 'JavaScript',
		logo: 'https://res.cloudinary.com/vinodkotagiri/image/upload/v1665328392/My%20Websites%20assets/logos/js_yxno8i.svg',
		color: '#f1dc4f',
	},
	{
		id: '4',
		name: 'Figma',
		logo: 'https://res.cloudinary.com/vinodkotagiri/image/upload/v1665328392/My%20Websites%20assets/logos/figma_cwofax.svg',
		color: '#02d085',
	},
	{
		id: '5',
		name: 'Bootstrap',
		logo: 'https://res.cloudinary.com/vinodkotagiri/image/upload/v1665328392/My%20Websites%20assets/logos/bootstrap_xcoad9.svg',
		color: '#563e7c',
	},
	{
		id: '6',
		name: 'MaterailUI',
		logo: 'https://res.cloudinary.com/vinodkotagiri/image/upload/v1665328392/My%20Websites%20assets/logos/mui_rh1spy.svg',
		color: '#0082cd',
	},
	{
		id: '7',
		name: 'AntDesign',
		logo: 'https://res.cloudinary.com/vinodkotagiri/image/upload/v1665328392/My%20Websites%20assets/logos/antd_l5btbc.svg',
		color: '#f74e5f',
	},
	{
		id: '8',
		name: 'React',
		logo: 'https://res.cloudinary.com/vinodkotagiri/image/upload/v1665328392/My%20Websites%20assets/logos/react_hzxqzv.svg',
		color: '#00d9ff',
	},
	{
		id: '9',
		name: 'NodeJs',
		logo: 'https://res.cloudinary.com/vinodkotagiri/image/upload/v1665329106/My%20Websites%20assets/logos/nodejs_e0yu5g.svg',
		color: '#529f41',
	},
	{
		id: '10',
		name: 'Python',
		logo: 'https://res.cloudinary.com/vinodkotagiri/image/upload/v1665329108/My%20Websites%20assets/logos/python_d1w28x.svg',
		color: '#5aa0d4',
	},
	{
		id: '11',
		name: 'MongoDB',
		logo: 'https://res.cloudinary.com/vinodkotagiri/image/upload/v1665329108/My%20Websites%20assets/logos/mongodb_jfe0cw.svg',
		color: '#53aa4b',
	},
	{
		id: '12',
		name: 'MySQL',
		logo: 'https://res.cloudinary.com/vinodkotagiri/image/upload/v1665329108/My%20Websites%20assets/logos/mysql_bmaevk.svg',
		color: '#2e8da2',
	},
	{
		id: '13',
		name: 'Git',
		logo: 'https://res.cloudinary.com/vinodkotagiri/image/upload/v1665329393/My%20Websites%20assets/logos/git_nlyelc.svg',
		color: '#f05030',
	},
	{
		id: '14',
		name: 'AWS',
		logo: 'https://res.cloudinary.com/vinodkotagiri/image/upload/v1665329393/My%20Websites%20assets/logos/aws_lgynjt.svg',
		color: '#ff9a02',
	},
	{
		id: '15',
		name: 'Linux',
		logo: 'https://res.cloudinary.com/vinodkotagiri/image/upload/v1665330282/My%20Websites%20assets/logos/linux_jmb4gj.svg',
		color: '#ffcc21',
	},
	{
		id: '17',
		name: 'Tailwind',
		logo: 'https://res.cloudinary.com/vinodkotagiri/image/upload/v1665684497/My%20Websites%20assets/logos/tailwind_vxpk6j.svg',
		color: '#44a8b3',
	},
	{
		id: '18',
		name: 'Firebase',
		logo: 'https://res.cloudinary.com/vinodkotagiri/image/upload/v1665770353/My%20Websites%20assets/logos/firebase_jbse13.svg',
		color: '#f68304',
	},
	{
		id: '19',
		name: 'Redux',
		logo: 'https://res.cloudinary.com/vinodkotagiri/image/upload/v1665899459/My%20Websites%20assets/logos/redux_urlu7c.svg',
		color: '#7749bd',
	},
]
